

module.exports  = {
  // token:'2079e52a-740e-42fb-887f-337571ee8ddd',
  token:'',
  host:"https://tdll-test.lintrondata.com/apit/", //测试环境、
  // host:"https://tdll-api.chinatdll.com/apit/", //正式
  // host:"http://10.8.117.230:8081/", //张华
  // host:"http://10.8.117.143:8081/", //童工
  // host:"http://10.8.117.239:8081/", //陈力
  // host:"http://10.8.117.210:8081/", //吴美琴
  // host:"http://10.8.205.222:8081/", //杨磊
  appId:18280020582,
  // appId:123456,
  pageArr:[10,20,30,40],
  validateIdCard: function(idCard) {
    let vcity={ 11:"北京",12:"天津",13:"河北",14:"山西",15:"内蒙古",
      21:"辽宁",22:"吉林",23:"黑龙江",31:"上海",32:"江苏",
      33:"浙江",34:"安徽",35:"福建",36:"江西",37:"山东",41:"河南",
      42:"湖北",43:"湖南",44:"广东",45:"广西",46:"海南",50:"重庆",
      51:"四川",52:"贵州",53:"云南",54:"西藏",61:"陕西",62:"甘肃",
      63:"青海",64:"宁夏",65:"新疆",71:"台湾",81:"香港",82:"澳门",91:"国外"
    };
    //是否为空
    if(idCard === ''){
      return false;
    }
    //校验长度，类型
    if(this.isCardNo(idCard) === false){
      return false;
    }
    //检查省份
    if(this.checkProvince(idCard,vcity) === false){
      return false;
    }
    //校验生日
    if(this.checkBirthday(idCard) === false){
      return false;
    }
    //检验位的检测
    if(this.checkParity(idCard) === false){
      return false;
    }
    return true;
  },
  isCardNo: function(card) {
    let reg = /(^\d{15}$)|(^\d{17}(\d|X|)$)/;
    if(reg.test(card) === false){
      return false;
    }
    return true;
  },
  checkProvince: function(card,vcity) {
    let province = card.substr(0,2);
    if(vcity[province] == undefined){
      return false;
    }
    return true;
  },
  checkBirthday: function(card) {
    let len = card.length,year,month,day,birthday;
    //身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
    if(len == '15'||len == '18'){
      year = len == '15'?'19'+card.substr(6,2):card.substr(6,4),
        month = len == '15'?card.substr(8,2):card.substr(10,2),
        day = len == '15'?card.substr(10,2):card.substr(12,2);
      birthday = new Date(year+'/'+month+'/'+day);
      return this.verifyBirthday(year,month,day,birthday);
    }
    return false;
  },
  verifyBirthday: function(year,month,day,birthday) {
    var now = new Date();
    var now_year = now.getFullYear();
    //年月日是否合理
    if(birthday.getFullYear() == year && (birthday.getMonth() + 1) == month && birthday.getDate() == day)
    {
      let time = now_year - year;
      if(time >= 0 && time <= 100)
      {
        return true;
      }
      return false;
    }
    return false;
  },
  changeFivteenToEighteen: function(card) {
    if(card.length == '15')
    {
      let arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2),
        arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'),
        cardTemp = 0, i;
      card = card.substr(0, 6) + '19' + card.substr(6, card.length - 6);
      for(i = 0; i < 17; i ++)
      {
        cardTemp += card.substr(i, 1) * arrInt[i];
      }
      card += arrCh[cardTemp % 11];
      return card;
    }
    return card;
  },
  checkParity: function(card){
    //15位转18位
    card = this.changeFivteenToEighteen(card);
    var len = card.length;
    if(len == '18'){
      var arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
      var arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
      var cardTemp = 0, i, valnum;
      for(i = 0; i < 17; i ++) {
        cardTemp += card.substr(i, 1) * arrInt[i];
      }
      valnum = arrCh[cardTemp % 11];
      if (valnum == card.substr(17, 1).toLocaleUpperCase())
      {
        return true;
      }
      return false;
    }
    return false;
  },
  calcAge: (e) => {
    let birthYear = e.substr(6, 4),
      birthMonth = e.substr(10, 2),
      birthDay = e.substr(12, 2),
      d = new Date(),
      returnAge;
    let nowYear = d.getFullYear(),
      nowMonth = d.getMonth() + 1,
      nowDay = d.getDate();

    if (nowYear == birthYear) {
      returnAge = 0; //同年 则为0岁
    } else {
      var ageDiff = nowYear - birthYear; //年之差
      if (ageDiff > 0) {
        if (nowMonth == birthMonth) {
          var dayDiff = nowDay - birthDay; //日之差
          if (dayDiff < 0) {
            returnAge = ageDiff - 1;
          }else if(dayDiff == 0){
            if(ageDiff==6||ageDiff==18||ageDiff==30){
              returnAge = ageDiff - 1;
            }else{
              returnAge = ageDiff;
            }
          } else {
            returnAge = ageDiff;
          }
        } else {
          var monthDiff = nowMonth - birthMonth; //月之差
          if (monthDiff < 0) {
            returnAge = ageDiff - 1;
          } else {
            returnAge = ageDiff;
          }
        }
      } else {
        returnAge = -1; //返回-1 表示出生日期输入错误 晚于今天
      }
    }
    return returnAge; //返回周岁年龄
  },
}



